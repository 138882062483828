;(function ($) {
  'use strict';
  $(document).ready(function () {

    var snapper = new Snap({
      element: document.getElementById('snap-content'),
      disable : 'right',
      touchToDrag : false
    });

    $('.btn-drawer-trigger').click( function() {
      if( snapper.state().state=="left" ){
        snapper.close();
      } else {
        snapper.open('left');
      }
    });

    $('.snap-drawer-nav').each(function() {

      // Get the children
      var $children = $(this).find('ul ul').addClass('children');

      // Active items
      var $on = $(this).find('.on');

      // Put an element for displaying children before the element
      $children.each(function() {
        $(this).before('<span class="children-toggle"></span>');
      });

      // Add active item to children toggle for 'on' nav items
      $on.each(function() {
        $(this).find('> .children-toggle').addClass('active');
      });

      var $toggle = $(this).find('.children-toggle');

      // Get our new children toggle element
      $toggle.click(function() {
        $(this).toggleClass('active');
        $(this).next('.children').toggle();
      });

    });

  });
}(jQuery));
